<template>
  <div class="payorder">
    <title-top>商城订单</title-top>
    <div
      class="row flex-around bg-white font24 f-666666 w100 position_f"
      style="top:1.18rem;max-width:750px;"
    >
      <div class="column flex-end h72" @click="onTab(0)">
        <span :class="{active:currentTab == 0}">全部</span>
        <span class="mt8 underline" :class="{active2:currentTab==0}"></span>
      </div>
      <div class="column flex-end h72" @click="onTab(1)">
        <span :class="{active:currentTab == 1}">待付款</span>
        <span class="mt8 underline" :class="{active2:currentTab==1}"></span>
      </div>
      <div class="column flex-end h72" @click="onTab(2)">
        <span :class="{active:currentTab == 2}">待发货</span>
        <span class="mt8 underline" :class="{active2:currentTab==2}"></span>
      </div>
      <div class="column flex-end h72" @click="onTab(3)">
        <span :class="{active:currentTab == 3}">待收货</span>
        <span class="mt8 underline" :class="{active2:currentTab==3}"></span>
      </div>
      <div class="column flex-end h72" @click="onTab(4)">
        <span :class="{active:currentTab == 4}">已完成</span>
        <span class="mt8 underline" :class="{active2:currentTab==4}"></span>
      </div>
    </div>
    <div class="h80" style="height:.97rem;"></div>
    <div class="bg-white" v-for="(item,index) in list" :key="index">
      <div class="row between align-center bg-white h80 font24">
        <span class="f-333333 ml25">订单编号：{{item.number}}</span>
        <span class="row flex-center state br28 f-F62341 mr25">{{item.state_zh_cn}}</span>
      </div>
      <div class="line"></div>
      <div
        class="row between align-center bg-white h204"
        v-for="(child,idx) in item.items"
        :key="idx"
        @click="onItem(item.id)"
      >
        <img class="imgsize176 br4 ml25" :src="child.image_url" alt />
        <div class="flex1 column font28 f-1A1A1A ml16">
          <p class="ellipsis font24 mr40">{{child.product_name}}</p>
          <div class="row between mt16 align-center">
            <span class="fontwb">￥{{child.price}}</span>
            <span class="mr25">×{{child.quantity}}</span>
          </div>
          <div class="row flex-center" v-for="(spac,x) in child.specification_values" :key="x">
            <span class="font24 f-999999 mr16">{{spac.content}}</span>
          </div>
        </div>
      </div>
      <div class="row between align-center h72 mb20 mr25 font28 f-1A1A1A">
        <div class="row align-center ml25">
          总计：
          <span class="fontwb">￥{{item.total_payment_amount}}</span>
        </div>
        <div class="row">
          <span
            class="row flex-center state br8 statecolor mr16"
            @click="onBtn1(item)"
          >{{formatBtn(item)[0]}}</span>
          <span
            class="row flex-center state br8 statecolor"
            @click="onBtn2(item)"
          >{{formatBtn(item)[1]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {
  orderList,
  cancelOrder,
  payOrder,
  receive,
  refundsDetail
} from "network/igou2";

export default {
  name: "OrderList",
  data() {
    return {
      list: [],
      currentTab: 0,
      state: ""
    };
  },
  mounted() {
    this._orderList();
  },
  filters: {},
  methods: {
    turn(state, id) {
      if (state == "under_review") {//完成
        this.$router.push({
          path: "/zyrefundhandle",
          query: { id: id }
        });
      } else if (state == "rejected") {//完成
        this.$router.push({
          path: "/zyreapply",
          query: { id: id }
        });
      } else if (state == "waiting_for_shipping") {//完成
        this.$router.push({
          path: "/zywritelogistics",
          query: { id: id }
        });
      } else if (state == "shipped") {//完成
        this.$router.push({
          path: "/zyrefundgoods",
          query: { id: id }
        });
      } else if (state == "finished") {//完成
        this.$router.push({
          path: "/zyrefundsuccess",
          query: { id: id }
        });
      } else if (state == "canceled") {//完成
        this.$router.push({
          path: "/zycanclerefund",
          query: { id: id }
        });
      }
    },
    formatBtn(item) {
      let state = item.state;
      if (state == "unpaid") {
        //待付款
        return ["取消订单", "立即付款", true, true];
      } else if (state == "paid") {
        //待发货
        if (item.refund_id) {
          return ["退换详情", "待发货", true, false];
        } else {
          return ["退换服务", "待发货", true, false];
        }
      } else if (state == "shipped") {
        //待收货
        if (item.refund_id) {
          return ["退换详情", "确认收货", true, true];
        } else {
          return ["退换服务", "确认收货", true, true];
        }
      } else if (state == "received") {
        //已完成
        if (item.refund_id) {
          return ["退换详情", "已完成", true, false];
        } else {
          return ["退换服务", "已完成", true, false];
        }
      }
    },
    onItem(order_id) {
      this.$router.push({
        path: "/zyorderdetail",
        query: { order_id: order_id }
      });
    },
    onTab(index) {
      console.log(index);
      if (index == this.currentTab) {
        return;
      }
      this.currentTab = index;
      if (index == 0) {
        this.state = "";
        this._orderList();
      } else {
        if (index == 1) {
          this.state = "unpaid";
        } else if (index == 2) {
          this.state = "paid";
        } else if (index == 3) {
          this.state = "shipped";
        } else if (index == 4) {
          this.state = "received";
        }
        this._orderList(this.state);
      }
    },
    _orderList(state) {
      orderList(state).then(res => {
        console.log(res);
        this.list = res.data;
      });
    },
    _cancelOrder(id) {
      cancelOrder(id).then(res => {
        console.log(res);
        this._orderList();
      });
    },
    _receive(id) {
      receive(id).then(res => {
        console.log(res);
        this._orderList();
      });
    },
    _refundsDetail(id) {
      refundsDetail(id).then(res => {
        this.res = res.data;
        this.turn(res.data.state,id);
      });
    },
    onBtn1(item) {
      let state = item.state;
      if (state == "unpaid") {
        //待付款
        this._cancelOrder(item.id);
      } else {
        if (item.refund_id) {
          /*
          this.$router.push({
            path:'/zyrefundhandle',
            query:{id:item.refund_id}
            });
            */
          this._refundsDetail(item.refund_id);
          //return ['退换详情'];
        } else {
          this.$router.push({
            path: "/zychangeserve",
            query: { order_id: item.id, state: item.state }
          });
          //return ['退换服务'];
        }
      }
    },
    onCancle(id) {
      this._cancelOrder(id);
    },
    onBtn2(item) {
      let state = item.state;
      if (state == "unpaid") {
        //立即支付
        this.$router.push({
          path: "/zypayorder",
          query: { order_id: item.id }
        });
      } else if (state == "shipped") {
        // 确认收货
        this._receive(item.id);
      } else {
      }
    },
    _payOrder(address_id) {
      payOrder(address_id).then(res => {
        console.log(res);
        this.$router.push("/payorder");
      });
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.active {
  color: #41aba7;
}
.active2 {
  background-color: #41aba7;
}
.underline {
  height: 0.026667rem;
  width: 0.64rem;
}
.state {
  border: 0.013333rem solid #f62341;
  width: 1.546667rem;
  height: 0.64rem;
}
.statecolor {
  width: 1.866667rem;
  border-color: #1a1a1a;
}
</style>
